<template>
    <div class="grid grid-cols-4 gap-4">
        <div class="col-span-3 px-2 sm:px-8">
            <ValidationObserver class="block mt-1">
                <ValidationProvider name="full name" :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/ }">
                    <div class="input-field-label">Full Name*</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-user" />
                        <input v-model="form.name" placeholder="Full Name" class="input-field" @input="emptyForm()" />
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="this.form.validate.name">*Full name is required</small>
                </ValidationProvider>
                <ValidationProvider class="block mt-6" name="email" rules="required|email">
                    <div class="input-field-label">Email*</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-envelope" />
                        <input v-model="form.email" placeholder="Email" class="input-field" @input="emptyForm()" />
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="this.form.validate.email">*Email is required</small>
                </ValidationProvider>
                <ValidationProvider class="block mt-6" name="phone" :rules="{ required: true, regex: /^[0-9]+$/ }">
                    <div class="input-field-label">Phone</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-mobile-screen-button" />
                        <input v-model="form.phone" disabled placeholder="Phone" class="input-field" />
                    </div>
                </ValidationProvider>
                <ValidationProvider class="block mt-6" name="gender" rules="required">
                    <div class="input-field-label">Gender</div>
                    <div class="sm:flex justify-between">
                        <div>
                            <input v-model="form.gender" value="Male" type="radio" />
                            <button
                                @click="form.gender = 'Male'"
                                class="bg-primary hover:bg-primary-darker text-white rounded ml-4 py-1 pl-2 pr-4 inline-flex items-center mb-2 sm:mb-0"
                            >
                                <font-awesome-icon class="ml-2 mr-4 text-white" icon="fa-solid fa-mars" />
                                <div>Male</div>
                            </button>
                        </div>
                        <div>
                            <input v-model="form.gender" value="Female" type="radio" />
                            <button
                                @click="form.gender = 'Female'"
                                class="bg-primary hover:bg-primary-darker text-white rounded ml-4 py-1 pl-2 pr-4 inline-flex items-center"
                            >
                                <font-awesome-icon class="ml-2 mr-4 text-white" icon="fa-solid fa-venus" />
                                <div>Female</div>
                            </button>
                        </div>
                    </div>
                </ValidationProvider>
                <ValidationProvider class="block mt-6" name="birth date" rules="required">
                    <div class="input-field-label">Birth Date*</div>
                    <div class="flex items-end">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-calendar-days" />
                        <v-date-picker
                            v-model="form.birthDate"
                            :max-date="maxDate"
                            :masks="{ input: 'DD MMMM YYYY' }"
                            class="w-full"
                        >
                            <template v-slot="{ inputValue, togglePopover }">
                                <input
                                    :value="inputValue"
                                    @click="togglePopover"
                                    placeholder="Birth Date"
                                    class="input-field cursor-pointer"
                                    readonly
                                />
                            </template>
                        </v-date-picker>
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="this.form.validate.birthDate">*Birth date is required</small>
                </ValidationProvider>
                <div class="flex justify-between mt-12">
                    <button @click="resetForm" class="button-cancel">Cancel</button>
                    <button @click="updateProfile" class="button-submit">Update</button>
                </div>
            </ValidationObserver>
        </div>
        <div>
            <qrcode
                :value="$store.state.user.va"
                :options="{ width: 240 }"
                tag="img"
                class="border border-gray-200 rounded-2xl shadow-lg"
            ></qrcode>
            <div class="mt-4 text-sm font-semibold text-center">My QR Code</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountProfile',

        data() {
            return {
                form: {
                    name: null,
                    email: null,
                    phone: null,
                    gender: null,
                    birthDate: null,
                    validate: {
                        name: false,
                        email: false,
                        birthDate: false,
                    }
                },
                maxDate: this.$moment().subtract(13, 'years').format('YYYY-MM-DD')
            };
        },

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Account Setting', routeName: 'AccountProfile' },
                { text: 'Account', routeName: 'AccountProfile' }
            ]);

            this.resetForm();
        },

        methods: {
            resetForm() {
                this.form = Object.assign({}, this.form, {
                    name: this.$store.state.user.nama,
                    email: this.$store.state.user.email,
                    phone: this.$store.state.user.telepon,
                    gender: this.$store.state.user.jk,
                    birthDate: this.$store.state.user.tgllahir
                });

                if (this.$moment(this.form.birthDate).isAfter(this.maxDate)) {
                    this.form.birthDate = null;
                }
            },
            emptyForm(){
                this.form.validate.name = false
                this.form.validate.email = false
                this.form.validate.birthDate = false
            },
            async updateProfile() {
                if(this.form.name == '' || this.form.name == null){
                    this.form.validate.name = true
                    return false
                }
                if(this.form.email == '' || this.form.email == null){
                    this.form.validate.email = true
                    return false
                }
                if(this.form.birthDate == '' || this.form.birthDate == null){
                    this.form.validate.birthDate = true
                    return false
                }
                this.$store.commit('setOverlayLoading', true);

                try {
                    const response = await this.$http.post(
                        `${this.$apiTripweWeb}/user/profile/update`,
                        {
                            "nama": this.form.name,
                            "jk": this.form.gender,
                            "tgllahir": (this.$moment(this.form.birthDate).format('YYYY-MM-DD')),
                            "email": this.form.email,
                        }
                    );

                    if (response.data.status != '200') {
                        throw 'Update profile failed';
                    }

                    this.$store.commit(
                        'setUser',
                        Object.assign({}, this.$store.state.user, {
                            nama: this.form.name,
                            jk: this.form.gender,
                            tgllahir: (this.$moment(this.form.birthDate).format('YYYY-MM-DD')),
                            email: this.form.email,
                        })
                    );

                    await this.$nextTick();

                    this.resetForm();

                    this.$toasted.global.success('Your profile has been updated');
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit('setOverlayLoading', false);
            }
        }
    };
</script>
